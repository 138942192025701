<template>
  <div class="home">
    <HelloWorld msg="Something is brewing"/>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld
  }
}
</script>
<style>
body {
    background-color: rgb(6, 4, 4);
    background-repeat: no-repeat;
    background-attachment: fixed; 
    background-size: 100% 100%;

}

</style>
<!--    background-image: url('~@/assets/landing.png');
-->
